import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/shared/JssOverrides';
import { withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import Button from '../shared/Button';
import {NotFoundWrapper, Container, NotFoundWrapperFs} from './NotFound.styles.js';
import { trademarkFilterHelper } from "../../helpers/trademarkFilterHelper";
import DogImage from '../../assets/images/duke.png';
import FourOhFourImage from '../../assets/images/404.svg';
import { isServer } from "@sitecore-jss/sitecore-jss";
import translate from "../../helpers/translate";
import SearchBox from '../SearchBox/SearchBox.jsx';
import styled from "styled-components";

const NotFoundPage = ({ route, fields = {}, params = {}, context, sitecoreContext }) => {
    const { viewBag = {} } = context || {};
    const { content = {}, heading, button1, button2, button3, button4 } = fields;
    const recipePagePath = 'bean-recipes';
    const pagePath = useLocation().pathname;
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState(false);
    const [noSearchValue, setNoSearchValue] = useState(false);
    const searchRef = useRef(null);

    const { site } = sitecoreContext;
    //console.log('FindNearYou.jsx fields', fields);
    const handleRedirectRouting = () => {
        if (!isServer()) {
            const splitPath = window.location.pathname.split('/');
            const regex = new RegExp(/^([a-z]{2}_[A-Z]{2})/);

            if (regex.test(splitPath[1])) {
                if (splitPath[2] === recipePagePath) {
                    return `/${splitPath[1]}/${splitPath[2]}/`;
                }
                return `/${splitPath[1]}/`;
            }
            return '/';
        }
    };

    useEffect(() => {
        const searchButton = document.getElementById('search-button');
        if (searchButton) {
            const svgElement = searchButton.querySelector('svg');
            if (svgElement) {
                svgElement.style.display = 'none';
            }
        }
    }, []);

    switch (site && site.name) {
        case 'bushs-beans-foodservices':
            return (
                <NotFoundWrapperFs>
                    <Container className="inner">
                        <section aria-label="Search Container" className="search-on-p">
                            <SearchBox
                                searchValue={searchTerm}
                                showError={noSearchValue}
                                onChange={(event) => {
                                    setSearchTerm(event);
                                    if (noSearchValue) setNoSearchValue(false);
                                }}
                                onSubmit={(event, inputValue) => {
                                    if (searchTerm.length > 0) {
                                        window.location.href = `/search/?type=all&searchTerm=${searchTerm}`;
                                        setSearch(false);
                                    } else {
                                        setNoSearchValue(true);
                                    }
                                }}
                                className="search-box"
                            />
                        </section>
                        <div>
                            <h1 className="blue text-center 404h1">
                                <img width="200" src={FourOhFourImage} alt="404 Page Not Found" />
                            </h1>
                            {heading?.value || "Sorry we couldn't find this page." && (
                                <h2 className="blue" dangerouslySetInnerHTML={{__html: heading?.value || "Sorry we couldn't find this page."}}></h2>
                            )}
                            <p>Search our site or explore some links below:</p>
                            <ul className="buttonUl foodservice">
                                {button1?.value?.text && button1?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button1.value.text}
                                            href={button1.value.href}
                                            target={button1.value.target}
                                            {...button1.value}
                                            variant="yellowBlue"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <Button className="fourOfourButton" text="Back to Home" href="/" variant="yellowBlue" />
                                    </li>
                                )}
                                {button2?.value?.text && button2?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button2.value.text}
                                            href={button2.value.href}
                                            target={button2.value.target}
                                            {...button2.value}
                                            variant="yellowBlue"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <Button className="fourOfourButton" text="Explore Recipes" href="/recipes" variant="yellowBlue" />
                                    </li>
                                )}
                                {button3?.value?.text && button3?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button3.value.text}
                                            href={button3.value.href}
                                            target={button3.value.target}
                                            {...button3.value}
                                            variant="yellowBlue"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <Button className="fourOfourButton" text="Explore Products" href="/bulk-products" variant="yellowBlue" />
                                    </li>
                                )}
                                {button4?.value?.text && button4?.value?.href&& (
                                    <li>
                                        <Button
                                            text={button4.value.text}
                                            href={button4.value.href}
                                            target={button4.value.target}
                                            {...button4.value}
                                            variant="yellowBlue"
                                            className="fade-in-up"
                                        />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </Container>
                </NotFoundWrapperFs>
            );
        default:
            return (
                <NotFoundWrapper>
                    <Container className="inner">
                        <section aria-label="Search Container" className="search-on-p">
                            <SearchBox
                                searchValue={searchTerm}
                                showError={noSearchValue}
                                onChange={(event) => {
                                    setSearchTerm(event);
                                    if (noSearchValue) setNoSearchValue(false);
                                }}
                                onSubmit={(event, inputValue) => {
                                    if (searchTerm.length > 0) {
                                        window.location.href = `/search/?type=all&searchTerm=${searchTerm}`;
                                        setSearch(false);
                                    } else {
                                        setNoSearchValue(true);
                                    }
                                }}
                                className="search-box"
                            />
                        </section>
                        <div>
                            <h1>404</h1>
                            {heading?.value || "THIS PAGE IS NOWHERE TO BEAN FOUND" && (
                                <h2 dangerouslySetInnerHTML={{__html: heading?.value || "THIS PAGE IS NOWHERE TO BEAN FOUND"}}></h2>
                            )}
                            <h3>HERE ARE SOME OTHER PLACES TO SNIFF OUT</h3>
                            <ul className="buttonUl">
                                {button1?.value?.text && button1?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button1.value.text}
                                            href={button1.value.href}
                                            target={button1.value.target}
                                            {...button1.value}
                                            variant="brown"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <a href="/en_US/">Home Page</a>
                                    </li>
                                )}
                                {button2?.value?.text && button2?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button2.value.text}
                                            href={button2.value.href}
                                            target={button2.value.target}
                                            {...button2.value}
                                            variant="brown"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <a href="/en_US/products">All Products</a>
                                    </li>
                                )}
                                {button3?.value?.text && button3?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button3.value.text}
                                            href={button3.value.href}
                                            target={button3.value.target}
                                            {...button3.value}
                                            variant="brown"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <a href="/en_US/about-us">About Us</a>
                                    </li>
                                )}
                                {button4?.value?.text && button4?.value?.href ? (
                                    <li>
                                        <Button
                                            text={button4.value.text}
                                            href={button4.value.href}
                                            target={button4.value.target}
                                            {...button4.value}
                                            variant="brown"
                                            className="fade-in-up"
                                        />
                                    </li>
                                ) : (
                                    <li>
                                        <a href="/en_US/bean-recipes">Bean Recipes</a>
                                    </li>
                                )}
                            </ul>

                            <img src={DogImage} alt="Duke" />
                        </div>
                    </Container>
                </NotFoundWrapper>
            );
    }
};

export default withSitecoreContext()(NotFoundPage);